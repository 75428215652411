import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku10.module.scss"

const Luukku10 = props => {
  //console.log('Rendering Luukku10', props)
  const nro = 10
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku10.png" alt="" />
      <h1 className="luukku-heading">Isopupun Puhuva Lumipallo </h1>

      <p>Pupuperhe saatteli Petterin Isopupun luo, valtavan puskan juurelle.</p>
      <p>
        – Isopupu! Isopupu! Me täällä ja sitten meidän mukanamme on uusi ystävä, sanoi pupuperhe.<br></br> – Kylläpä te
        kehtaatte tulla keskellä yötä herättämään. Pitää olla tärkeää asiaa, Isopupu murisi.<br></br> – Ja niin onkin
        tärkeää asiaa. Meidän ystävämme Petteri on eksynyt eikä löydä takaisin kotiinsa Korvatunturille.<br></br> – Ja
        mitenkäs tämä ongelma minuun liittyy? Hus, ulos puskastani! Ei minulla ole aikaa auttaa jokaista eksynyttä. Sitä
        paitsi nyt on yö ja olin juuri keskellä mahtavan herkullista unta, Isopupu sanoi.
      </p>
      <p>
        –Taitaa olla huono hetki nyt. Kokeillaan uudestaan aamulla. Isopupu on vähän oikukas ja äkäinen varsinkin
        yöaikaan, pupuperhe sanoi ja viittoi Petteriä matkaansa. <br></br>– No tulkaa nyt sitten sisään! En kuitenkaan
        saa enää nukuttua, kun herätitte minut jo. Missään nimessä en haluaisi auttaa, mutta ilmeisesti odotatte minun
        tekevän jotakin, Isopupu puhisi.<br></br> – Ajattelimme, että voisit kysyä Puhuvalta Lumipallolta ohjeita, kun
        Petterin punainen kuono ei toimi. Tai siis sen suuntavaisto on rikki ja hänen pitäisi löytää takaisin kotiin.
        <br></br> – Vai punainen nenä ei toimi! Kaikenlaista hölynpölyä sitä joutuu Lumipallolta kysymään, Isopupu
        käkätti. Mutta hyvä on! Kysyn, mutta vain tämän yhden kerran.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku10
