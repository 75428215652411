import { StaticImage } from "gatsby-plugin-image"
import React from "react"

//import * as styles from "./Luukku1.module.scss"

const Luukku1 = props => {
  //console.log('Rendering Luukku1', props)
  const nro = 1
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku1.png" alt="" />

      <h1 className="luukku-heading">Kulkunen nukkuu jauhokaapissa </h1>
      <p>
        Ja niin se päivä viimein oli taas täällä. Joulukuun ensimmäinen. Tätä päivää oli Korvatunturilla odotettu
        pitkään, aina kesästä alkaen. Kuten aina joulukuun ensimmäisenä, niin tälläkin kertaa, Joulumuori aloitti
        jouluvalmistelut varmistamalla, että kaikki piparimuotit olivat tallella keittiön laatikoissa. Tämä oli sangen
        tärkeä tehtävä, sillä joskus leikkisät tontut saattoivat piilottaa ne hassutellakseen tai tehdäkseen jekkuja
        Muorille. Mutta tällä kertaa vaikutti kaikki muotit olevan paikallaan: Tähti, sydän, kuusi ja Petteri.
        Puuttuikohan tästä nyt joku? Aivan, tietysti! Piparin muotoinen muotti puuttui. Ei kun olihan se sekin tallessa
        laatikon aivan perimmäisessä nurkassa.
      </p>
      <p>
        – No niin! Hienoa hienoa, Muori hyrisi tyytyväisenä itselleen ja siirtyi sitten keittiön kaapeille katsomaan,
        vieläkö siellä oli riittävästi kanelia, sokeria, jauhoja ja siirappia joulupipareihin ja muihin leivonnaisiin.
        <br></br> – No mutta kukas se täällä nukkuu?, Muori ihmetteli ääneen. Hyllyllä pötkötteli unisena Kulkunen eli
        Muorin ja Joulupukin kissa. Sen sanottiin olevan jopa 200-vuotta vanha, mutta sitä ei kyllä ikinä uskoisi, sillä
        se oli yleensä niin täynnä vauhtia. Tänään Kulkunen kuitenkin raotti ainoastaan laiskasti toista silmäänsä,
        nuolaisi kevyesti Muoria nenänpäästä, mutta jatkoi sitten uniaan sokeripussin ja kanelipurkin välissä kehräten.
        Muori silitti pehmeää Kulkusta hetkisen ja päätti sitten itsekin istahtaa hetkeksi keinutuoliin ja juoda kupin
        herkullista kaakaota.
      </p>

      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku1
