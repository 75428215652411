import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku3.module.scss"

const Luukku3 = props => {
  //console.log('Rendering Luukku3', props)
  const nro = 3
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku3.png" alt="" />

      <h1 className="luukku-heading">Joulupukki neuvoo Kulkusta</h1>
      <p>
        Kulkunen jolkotti vauhdilla takaisin kotiin ja suunnisti suoraan Joulupukin huoneeseen. Pukki pötkötteli
        sohvalla ja luki suurta kirjaansa, jota vain harva sai katsoa. Kirjaan oli kuulemma kirjoitettu paljon tärkeitä
        salaisuuksia, eikä niitä voinut näyttää kaikille. Ovela Kulkunen kuitenkin tiesi kirjan sisällön, sillä hän oli
        lukenut sitä salaa Pukin nukkuessa. Hän ei ollut mitenkään voinut vastustaa kiusausta, vaan hänen oli ollut
        pakko saada selville, mitä kirjassa oikein luki. Ja siellähän oli maailman kaikkien lasten joululahjatoiveet
        aakkosjärjestyksessä. Voi jukra, että niitä oli paljon!
      </p>
      <p>Nyt Kulkunen kuitenkin keskittyi käsillä olevaan asiaan, pomppasi Pukin pullean vatsan päälle ja maukui.</p>
      <p>
        – Nooh, mitäs meidän Kulkuselle kuuluu tänään?, Pukki kysyi ja rapsutti Kulkusta päänlaelta.<br></br> – Hyvää
        muuten, mutta Petteri ei ole aitauksessa niin kuin kuuluisi.<br></br> – Ai niinkö? No sepä kummallista. Oletko
        käynyt jo saunalla? Petteri aloitti saunomisen muutama viikko sitten hiirten kanssa. Joku hupaisa kilpailu
        kuulemma. Kuka jaksaa istua löylyssä pisimpään tai muuta vastaavaa. Kipaisepa katsomassa sieltä.<br></br> – Ai
        saunasta? Kuka ihme siellä kuumassa viihtyy?, Kulkunen puisteli päätään, mutta päätti lähteä silti katsomaan.
        <br></br> – Hyvä, hyvä ja tule sitten kertomaan löytyikö Petteri. Minä jatkan nyt lukupuuhia ja alan laatia
        työjärjestystä lelutehtaalle, Joulupukki sanoi.{" "}
      </p>

      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku3
