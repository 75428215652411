import { StaticImage } from "gatsby-plugin-image"
import React from "react"
// import YoutubeEmbed from "../YoutubeEmbed"
//import * as styles from "./Luukku4.module.scss"

const Luukku4 = props => {
  //console.log('Rendering Luukku4', props)
  const nro = 4
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku4.png" alt="" />

      <h1 className="luukku-heading">Saunovat hiiret</h1>
      <p>
        Kulkusen lähestyessä saunaa hän löysi hiiret pihalta vilvoittelemassa kylpytakit päällä. Ulos oli kannettu myös
        iso kimpale juustoa, josta he vuoron perää nakersivat palasen.
      </p>

      <p>
        – Anteeksi, kun häiritsen, mutta onkohan Punakuonoa näkynyt? Pukki sanoi, että voisi olla täällä teidän
        kanssanne saunomassa.<br></br> – Eheei oo näkyny, hiiret lauloivat yhteen ääneen.<br></br> – No tiedättekö missä
        hän voisi olla?<br></br> – Eheei me tiietä mitään ja vaikka tiiettäiski ni ei kerrottais, hiiret nauroivat
        yhteen ääneen.<br></br> – Pyh!, Kulkunen murisi itsekseen. Hölmöt hiiret! Hyödytöntä sakkia koko porukka.
      </p>

      <p>
        Kulkunen käänsi hiirille takapuolensa, huiskaisi heidät hännällään kumoon ja lähti laahustamaan kotia kohti.
        Jotakin eriskummallista oli tekeillä, se oli ilmiselvää. Mutta mitä? Ei Petteri Punakuono yleensä tällä tavoin
        ilmoittamatta kadonnut. Asia piti selvittää! Mitä pikemmin, sitä parempi.
      </p>

      <p>
        Kuten aina kun häntä vaivasi jokin, Kulkunen otti äkkinäisen spurtin ja loikki pihakoivuun, omalle
        mietintäpaikalleen, istumaan. Täällä oli hyvä tarkkailla tilannetta ja ajatella. Hetkenä minä hyvänsä hän
        ratkaisisi tämän pähkinän. Piti vaan odottaa oksalla, että vastaus tipahtaisi hänen päähänsä.
      </p>

      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku4
