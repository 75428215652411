import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku22.module.scss"

const Luukku22 = props => {
  //console.log('Rendering Luukku22', props)
  const nro = 22
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku22.png" alt="" />

      <h1 className="luukku-heading">Muori yllättää Kulkusen</h1>
      <p>
        Jouluaattoaamuna kenenkään ei tarvinnut kytkeä herätyskelloaan päälle. Yleensä pitkälle iltapäivään nukkuvat
        hiiret, tulivat aina silloin aamuvarhaisella jokaisen ikkunan taakse laulamaan joululaulua nimeltä Tonttu. He
        harjoittelivat esitystään koko joulukuun ja konsertti oli vaikuttava. Kulkunen, kuka hiirten lailla tykkäsi
        nukkua pitkään, ei oikein innostunut konsertista, vaan veti keittiön jauhokaapin ovea tiiviimmin kiinni. Hiiret
        eivät kuitenkaan luovuttaneet, vaan alkoivat laulaa lujempaa ja lopulta Kulkusenkin oli noustava jaloilleen.
      </p>
      <p>
        – Hyvä on! Hyvä on! Olen ylhäällä, Kulkunen sihisi ja hyppäsi jauhokaapista tiskipöydälle.<br></br> – Hyvää
        Joulua Kulkunen, Muori sanoi ja silitti sen pehmeää turkkia. Tuossa on sinulle kupillinen kermavaahtoa Joulun
        kunniaksi ja muutenkin, että jaksat tänään. Edessä kun on pitkä päivä.<br></br> – Kiitoksia Muoruli, mutta mitä
        siis tarkoitat pitkällä päivällä?, Kulkunen kysyi.<br></br> – No sitä, kun lähdet Pukin ja Punakuonon kanssa
        jakamaan lahjoja.<br></br> – MITÄ?! Pääsenkö minä mukaan jakamaan lahjoja?, Kulkunen maukui innoissaan.<br></br>{" "}
        – Eikö Pukki kertonut?, Muori ihmetteli.<br></br>– Ei kertonut, mutta mikään voima maailmassa ei enää estä minua
        lähtemästä! Menen heti pakkaamaan uuden reppuni täyteen matkatavaroita. Tulen ihan kohta hakemaan eväät!,
        Kulkunen ilmoitti ja lähti innoissaan pakkauspuuhiin.
      </p>

      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku22
