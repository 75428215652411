import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku18.module.scss"

const Luukku18 = props => {
  //console.log('Rendering Luukku18', props)
  const nro = 18
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku18.png" alt="" />

      <h1 className="luukku-heading">Ystävyyden reki laskeutuu Korvatunturille</h1>

      <p>
        – Kotonaa! Viimeinkin ollaan kotonaa!, Kulkunen kiljui ja teki pienen voiton tanssin.<br></br> – Ja vielä
        ajoissa aatonaaton puurojuhlaan, Pukki lisäsi.<br></br> – Onpa ihanaa olla kotona, Petteri huokaisi onnellisena.
        <br></br> – Nyt alkaa todellakin tuntua Joululta, tokaisi puolestaan Taika-tonttu.{" "}
      </p>
      <p>
        Petteri, Kulkunen ja Taika ampaisivat juoksuun ja kilpailivat kuka olisi ensimmäisenä Muorin ja Pukin kotona.
        Pukki puolestaan nilkutti tyytyväisenä hiljalleen perässä. Hänestä oli ihana seurata, kuinka iloisia kaikki
        olivat. Joulu oli vuoden kohokohta ja juhlista parhain. Ei siitä päässyt minnekään.
      </p>
      <p>
        – No siinähän te olette! Voi, miten ihanaa nähdä teitä kaikkia! Erityisesti sinua Punakuono, Muori sanoi ja
        sulki kolmikon lämpimään halaukseen. Tulkaa, tulkaahan nyt kiireesti sisään. Ihan kohta syödään ja sen jälkeen
        lauletaan ja leikitään.
      </p>
      <p>
        Ja niin kolmikko istui ison pöydän ääreen, tonttujen, hiiriperheen, oravien, Pukin ja Muorin kanssa syömään
        puuroa sekä muita herkkuja. Kaikki nauroivat ja olivat mitä parhaimmalla joulumielellä.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku18
