import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

//import * as styles from "./SEO.module.scss"

/**
 * Send google gtag event
 * @param {*} category string - required - The object that was interacted with (e.g.video)
 * @param {*} action string - required - Type of interaction (e.g. 'play')
 * @param {*} label string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
 * @param {*} value number - optional - Numeric value associated with the event. (e.g. A product ID)
 */
export const gtag = (category, action, label, value) => {
  if (typeof window !== "undefined" && window.gtag && category && action) {
    const data = {
      event_category: category,
      event_label: label,
      value: value,
    }

    window.gtag("event", action, data)
  }
}

const SEO = props => {
  //console.log('Rendering SEO', props)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteName
            siteUrl
          }
        }
      }
    `
  )

  const siteName = props.siteName || site.siteMetadata.siteName
  const siteUrl = props.siteUrl || site.siteMetadata.siteUrl
  const title = props.title || site.siteMetadata.title
  const description = props.description || site.siteMetadata.description
  const image = props.image || siteUrl

  return (
    <Helmet titleTemplate={`%s | ${siteName}`}>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* <!--  Essential META Tags --> */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content="https://joulu.porinyhasunnot.fi/static/herokuva-joulu-porin-13c4909482029ae3e777432689e0d5c1.png"
      />
      <meta property="og:url" content={siteUrl} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:image:alt" content={siteName} />

      {/* <!--  Non-Essential, But Required for Analytics --> */}
      {/* <meta property="fb:app_id" content="your_app_id" />
      <meta name="twitter:site" content="@website-username" /> */}
    </Helmet>
  )
}

export default SEO
