import { StaticImage } from "gatsby-plugin-image"
import React from "react"

//import * as styles from "./Luukku12.module.scss"

const Luukku12 = props => {
  //console.log('Rendering Luukku12', props)
  const nro = 12
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku12.png" alt="" />

      <h1 className="luukku-heading">Ystävyyden reki laskeutuu Lattialuutunmäelle</h1>
      <p>
        Kulkunen, Joulupukki ja Taika-tonttu lensivät nopeammin kuin raketti pilvien päällä, kehuen vuoronperää Petteri
        Punakuonoa. Yhtäkkiä eväslaukussa mukana ollut iPad alkoi piippaamaan ja ilmoitti, että aivan tuossa tuokiossa
        he olisivat perillä Lattialuutunmäellä.
      </p>
      <p>
        – Ihanaa! Kohta pääsen halaamaan Petteriä, Kulkunen ilmoitti iloisena.<br></br> – Mahtavaa kerrassaan, mutta
        yksi pienen pieni asia olisi vielä, sanoi Joulupukki. Kas kun en ole koskaan aiemmin käyttänyt tätä rekeä enkä
        ihan tarkkaan tiedä miten sen kanssa kuuluu laskeutua.<br></br> – No mutta ratkaisuhan on ilmiselvä, Kulkunen
        ilmoitti viisaannäköisenä. Jos reki kerran nousee ilmaan kehuilla, niin eikö se saada sitten laskeutumaan
        haukuilla?<br></br> – En usko, että se toimii ihan noin, sanoi Taika-tonttu.<br></br> – No ehdota itse sitten
        jotakin parempaa!, Kulkunen tiukkasi.<br></br> – Rauhoitutaanpas nyt joka iikka. Aivan varmasti keksimme
        ratkaisun, kun pohdimme sitä yhdessä, sanoi Pukki.
      </p>

      <p>
        – Minä ehdotan, että aletaan pomppia täällä. Aivan varmasti saamme reen siten alas, sanoi Kulkunen.<br></br> –
        Minä taas ehdotan, että puhumme reelle ja pyydämme sitä laskeutumaan, sanoi Taika-tonttu.<br></br> – Eikun
        lauletaan reelle tai kerrotaan vitsejä, Kulkunen nauroi.<br></br> – Tai sitten tehdään kaikkia hulluja
        naamanilmeitä, kikatti Taika.<br></br> – Tai pieruääniä pruut pruut!, räkätti Kulkunen.
      </p>

      <p>
        Pukki katseli hymyillen tätä nauravaa kaksikkoa, ketkä eivät olleet hassuttelultaan ollenkaan huomanneet, että
        reki oli itseasiassa melkein jo maan tasalla. Aivan tuossa tuokiossa se kopsahtaisi keskelle lumihankia. Nauru
        oli siis se mitä tarvittiin onnistuneeseen laskeutumiseen. Olisihan se pitänyt arvata, Pukki hyräili
        tyytyväisenä itsekseen.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku12
