import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku5.module.scss"

const Luukku5 = props => {
  //console.log('Rendering Luukku5', props)
  const nro = 5
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku5.png" alt="" />

      <h1 className="luukku-heading">Ipadi! Ipadi!</h1>
      <p>
        Ja niinhän siinä kävi, että Kulkusen päähän tipahti jotakin. Nimittäin valtava kasa lunta. Puun oravat olivat
        saaneet hauskan idean ja päättäneet heittää Kulkusta lumipalloilla. Mutta Kulkunen oli tottunut oravien
        temppuihin eikä siksi hämmentynyt lumipallosateesta, vaikka olihan se harmillisesti keskeyttänyt hänen
        mietiskelytuokionsa. Pienen hetken Kulkunen harkitsi lähtemistä leikkiin mukaan, mutta päätti sittenkin hypätä
        puusta alas ja lähteä kotiin syömään. Tyhjällä vatsalla kun oli hankalaa ajatella tai varsinkaan keksiä mitään
        oivalluksia.
      </p>
      <p>
        Muori oli tehnyt lounaaksi herkkusilakoita, mutta jostain kummallisesta syystä Kulkuselle ne eivät maistuneet.
        Hän oli surullinen ja huolissaan. Niinpä hän päätti hypätä nojatuoliin nokosille. Yleensä lepo auttoi ja unten
        jälkeen mieli olisi taas kirkkaampi.
      </p>
      <p>
        Joku oli kuitenkin jättänyt iPadin nojatuolille ja se teki unipaikasta epämiellyttävän kovan ja kulmikkaan.
        Ärsyttävää, Kulkunen murisi, hyppäsi lattialle ja laahusti takan viereen makoilemaan.
      </p>
      <p>
        Juuri kun hän oli nukahtamassa, hänen mieleensä juolahti jotakin. Ratkaisu! Nimittäin Ipadi. Ipadi! Juuri niin.
        Tietysti. Vastaus oli hetki sitten ollut suoraan hänen mahansa alla. Kyseistä laitetta hyödyntämällä Petteri
        Punakuono löytyisi tuossa tuokiossa.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku5
