import { StaticImage } from "gatsby-plugin-image"
import React from "react"

// import FormDisclaimer from "../FormDisclaimer"
//import * as styles from "./Luukku7.module.scss"

const Luukku7 = props => {
  //console.log('Rendering Luukku7', props)
  const nro = 7
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku7.png" alt="" />

      <h1 className="luukku-heading">Ystävyyden reellä matkaan</h1>
      <p>
        Joulupukki oli kuullut yläkerrasta Kulkusen ja Taika-tontun keskustelun sekä päättänyt samoin tein lähtevänsä
        mukaan hakemaan Petteriä. Lahjavalmistelut olivat hyvässä vauhdissa ja tontut pärjäisivät vallan mainiosti
        lelutehtaalla muutaman päivän itsekseen.
      </p>
      <p>
        – Odottakaahan minua! Lähden mukaan, Pukki huuteli ja käveli hitaasti rappusia alas tukeutuen kävelykeppiinsä.
        Otetaan tallista Ystävyyden reki, joka on kaikista nopein ja kulkee ilman poroja!
      </p>
      <p>
        Taika-tonttu ja Kulkunen katsoivat toisiaan hämmästyneinä, koska kumpikaan heistä ei ollut koskaan kuullutkaan
        moisesta reestä.
      </p>
      <p>
        – En haluaisi vaikuttaa hölmöltä, mutta tarkoitatko siis, että reki kulkee niin sanotusti itsekseen?, Taika
        kysyi varovasti.<br></br> – Juuri niin! Tai ei ihan. Selitän myöhemmin, Pukki vastasi. <br></br>– Nyt kiireesti
        matkaan! Joko pakkasitte eväät? Ottakaahan vielä yksi pussillinen muorin joulupipareita mukaan, sillä ne ovat
        Petterin suosikkeja. Hän on varmasti hyvin nälkäinen oltuaan retkellään päivätolkulla, Pukki ohjeisti.
      </p>
      <p>Kun kaikki matkatarvikkeet olivat kasassa, suunnisti tämä kolmikko rekitallille päättäväisen näköisenä.</p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku7
