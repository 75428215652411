import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku19.module.scss"

const Luukku19 = props => {
  //console.log('Rendering Luukku19', props)
  const nro = 19
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku19.png" alt="" />

      <h1 className="luukku-heading">Pukki oivaltaa Petterin punaisen kuonon salaisuuden</h1>
      <p>
        Puurojuhlat olivat menestys, kuten kaikkina muinakin jouluina. Jättiläismäinen kattila oli tyhjä, samoin kuin
        pipari- ja torttutarjottimet, suklaakarkeista puhumattakaan. Kaikki nauroivat, lauloivat ja leikkivät yhdessä.
        Pukki istui keinutuolissaan ja katseli kaikkea tohinaa iloinen, mutta mietteliäs ilme kasvoillaan.
      </p>
      <p>
        – Mitä sinä täällä mietiskelet?, Muori kysyi.<br></br> – En mitään erityistä, Pukki vastasi salaperäisesti.
        <br></br> – Tiedäthän sinä, ettet pysty minulta mitään salaamaan, koska kuulen ajatuksesi, Muori sanoi ja
        suukotti Pukkia poskelle. Pukki hymyili ja suukotti häntä takaisin.
      </p>
      <p>
        Pukki oli hetki sitten oivaltanut Petterin punaisen kuonon salaisuuden. Liika yksinäisyys oli vienyt Petterin
        suuntavaiston ja siihen auttoi ainoastaan yhdessäolo. Asiasta ei tarvinnut olla huolissaan, sillä tilanne
        korjaantuisi itsestään. Aivan tuossa tuokiossa kuono toimisi taas täydellisesti, koska Petteri oli päässyt
        takaisin rakkaitten ystäviensä pariin, erityisesti Kulkusen. Tuo kaksikko oli erottamaton eikä heistä kumpikaan
        pärjännyt ilman toista. Sellaista se on, nimittäin tosiystävyys.
      </p>
      <p>
        Onpa mukavaa lähteä huomenna, Jouluaattona, tuon kaksikon kanssa jakamaan lahjoja maailman kaikille lapsille,
        Pukki ajatteli itsekseen.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku19
