import React, { useEffect, useRef, useState } from "react"
import Joulukalenteri from "./Joulukalenteri"
import ShareLinks from "./ShareLinks"

import { CSSTransition } from "react-transition-group"
import "../styles/global.scss"
import Modal from "./Modal"

// import SEO from "../components/SEO"

import Luukku1 from "./LuukkuContent/Luukku1"
import Luukku10 from "./LuukkuContent/Luukku10"
import Luukku11 from "./LuukkuContent/Luukku11"
import Luukku12 from "./LuukkuContent/Luukku12"
import Luukku13 from "./LuukkuContent/Luukku13"
import Luukku14 from "./LuukkuContent/Luukku14"
import Luukku15 from "./LuukkuContent/Luukku15"
import Luukku16 from "./LuukkuContent/Luukku16"
import Luukku17 from "./LuukkuContent/Luukku17"
import Luukku18 from "./LuukkuContent/Luukku18"
import Luukku19 from "./LuukkuContent/Luukku19"
import Luukku2 from "./LuukkuContent/Luukku2"
import Luukku20 from "./LuukkuContent/Luukku20"
import Luukku21 from "./LuukkuContent/Luukku21"
import Luukku22 from "./LuukkuContent/Luukku22"
import Luukku23 from "./LuukkuContent/Luukku23"
import Luukku24 from "./LuukkuContent/Luukku24"
import Luukku3 from "./LuukkuContent/Luukku3"
import Luukku4 from "./LuukkuContent/Luukku4"
import Luukku5 from "./LuukkuContent/Luukku5"
import Luukku6 from "./LuukkuContent/Luukku6"
import Luukku7 from "./LuukkuContent/Luukku7"
import Luukku8 from "./LuukkuContent/Luukku8"
import Luukku9 from "./LuukkuContent/Luukku9"

/** Application is in development mode */
export const devMode = false // process.env.NODE_ENV !== "production" && true

const luukkuContentMap: { [dayNum: number]: JSX.Element } = {
  1: <Luukku1 />,
  2: <Luukku2 />,
  3: <Luukku3 />,
  4: <Luukku4 />,
  5: <Luukku5 />,
  6: <Luukku6 />,
  7: <Luukku7 />,
  8: <Luukku8 />,
  9: <Luukku9 />,
  10: <Luukku10 />,
  11: <Luukku11 />,
  12: <Luukku12 />,
  13: <Luukku13 />,
  14: <Luukku14 />,
  15: <Luukku15 />,
  16: <Luukku16 />,
  17: <Luukku17 />,
  18: <Luukku18 />,
  19: <Luukku19 />,
  20: <Luukku20 />,
  21: <Luukku21 />,
  22: <Luukku22 />,
  23: <Luukku23 />,
  24: <Luukku24 />,
}

const Kalenterisivu = () => {
  const modalContentRef = useRef<HTMLElement>(null)

  /** Onko joku luukku avattu / Modal näkyvissä? `null` tarkoittaa että ei, joku numero että ko. numero on */
  const [openModalNum, setOpenModalNum] = useState<number | null>(null)

  const onLuukkuOpen = (luukkuNum: number) => {
    // console.debug("Kalenterisivu onLuukkuOpen", luukkuNum)
    setOpenModalNum(luukkuNum + 1)
  }

  useEffect(() => {
    modalContentRef.current && modalContentRef.current.scrollIntoView({ behavior: "smooth" })
  }, [openModalNum])

  return (
    <main>
      <div>
        <p className="pre-heading">
          Oletko jo kuullut Korvatunturin kissasta nimeltä Kulkunen tai Ystävyyden reestä, joka lentää ilman poroja ja
          nopeammin kuin raketti? Tiedätkö Petterin punaisen kuonon salaisuuden, entä onko Isopupun Puhuva Lumipallo
          sinulle entuudestaan tuttu?
          <br />
          <br />
          Nyt on aika kiinnittää turvavyö, avata ovi mielikuvitukselle ja osallistua ainutkertaiseen seikkailuun
          Korvatunturilla!
        </p>
      </div>

      {devMode && <p>! Test mode active !</p>}

      <Joulukalenteri onOpen={onLuukkuOpen} />
      <ShareLinks />

      {openModalNum !== null && luukkuContentMap[openModalNum] && (
        <CSSTransition classNames="fade" in={true} timeout={500} unmountOnExit>
          <Modal onClose={() => setOpenModalNum(null)}>
            <article ref={modalContentRef}>{luukkuContentMap[openModalNum]}</article>
          </Modal>
        </CSSTransition>
      )}
      {devMode && (
        <nav className="bottom-nav">
          <div>{/* <button onClick={reset}>Reset (dev)</button> */}</div>
        </nav>
      )}
    </main>
  )
}

export default Kalenterisivu
