import React, { useEffect, useState } from "react"
import Header from "../components/Header"
import Kalenterisivu, { devMode } from "../components/Kalenterisivu"
import SEO from "../components/SEO"

import "../styles/global.scss"

const IndexPage = () => {
  const [isLive, setIsLive] = useState(false)

  useEffect(() => {
    const now = Date.now() - new Date().getTimezoneOffset() * 60 * 1000
    const goLive = Date.UTC(2024, 11, 1, 0, 0)

    // console.debug(now, goLive, now - goLive)

    setIsLive(now > goLive)
  }, [setIsLive])

  // Kampanja käynnissä
  if (isLive || devMode) {
    return (
      <>
        <SEO />
        <Header />
        <Kalenterisivu />
      </>
    )
  }

  // Kampanja ei vielä alkanut
  return (
    <>
      <SEO />
      <Header />
      <main>
        <p className="pre-heading">Porin YH-Asunnot esittää:</p>
        <h1>Joulun ihastuttavin satujoulukalenteri</h1>

        {/* <p className="intro">Hups, olet paikalla etuajassa! Seikkailu alkaa 1.12.</p>  */}
        <p className="intro">Kalenteri on kiinni.</p>
        {/* <ShareLinks /> */}
      </main>
      {/* <Footer /> */}
    </>
  )

  // Kampanja päätynyt
  // <p>
  //       Kiitos, kun olit kanssamme toteuttamassa joulukalenteriamme! Toivottavasti sait mukavia lukuhetkistä yhdessä
  //       läheistesi kanssa.
  //     </p>
}

export default IndexPage
