import React, { useEffect, useRef, useState } from "react"
import { FaFacebookF, FaLinkedinIn, FaShareAlt, FaTwitter } from "react-icons/fa"
import { IoLink } from "react-icons/io5"
import { MdEmail } from "react-icons/md"
import { CSSTransition } from "react-transition-group"

import * as styles from "./ShareLinks.module.scss"

const isBrowser = typeof window !== "undefined"

type ShareLinksProps = { title: string; text: string; url?: string }

const ShareLinks = (props: ShareLinksProps) => {
  //console.log('Rendering ShareLinks', props)

  const [open, setOpen] = useState(false)
  const container = useRef(null)

  if (!isBrowser) return null

  /* Jaettavat sisällöt */
  const url = "https://joulu.porinyhasunnot.fi/"
  const title = "Joulun ihastuttavin joulukalenteri"
  const text = ""

  const clickHandler = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: text,
          url: url,
        })
        .then(() => {
          console.info("Successfully shared")
        })
        .catch(error => {
          console.error("Something went wrong sharing the blog", error)
        })
    } else {
      setOpen(!open)
    }
  }

  useEffect(() => {
    window.addEventListener("click", winClick)

    function winClick(e: MouseEvent) {
      if (open) {
        // find if clicked inside current nav tree, else close navigation
        let navClick = false
        let cur = e.target as HTMLElement
        while (cur) {
          //console.log(cur);
          if (cur === container.current) {
            navClick = true
            break
          }
          cur = cur.parentElement as HTMLElement
        }

        if (!navClick) setOpen(false)
      }
    }
  })

  return (
    <div className={styles.container} ref={container}>
      <button className={styles.button} onClick={clickHandler}>
        Jaa <FaShareAlt />
      </button>
      <CSSTransition classNames="fade" in={open} timeout={500} unmountOnExit>
        <div className={styles.links}>
          <a className={styles.link} onClick={() => navigator.clipboard.writeText(url)} tabIndex={0}>
            <span>Kopioi linkki</span>
            <IoLink />
          </a>
          <a className={styles.link} href={encodeURI(`mailto:subject=${title}&body=${text}%0A%0A${url}`)}>
            <span>Lähetä sähköposti</span>
            <MdEmail />
          </a>
          <a className={styles.link} href={encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}`)}>
            <span>Facebook</span>
            <FaFacebookF />
          </a>
          {/* <a className={styles.link} href="">
            <span>Instagram</span>
            <FaInstagram />
          </a> */}
          <a
            className={styles.link}
            href={encodeURI(
              `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${text}`
            )}>
            <span>LinkedIn</span>
            <FaLinkedinIn />
          </a>
          {/* <a className={styles.link} href={`https://pinterest.com/pin/create/button/?url=${imagesource}&media=${imageurl}&description=${teksti}`}>
            <span>Pinterest</span>
            <FaPinterest />
          </a> */}
          <a
            className={styles.link}
            href={encodeURI(`https://twitter.com/intent/tweet?text=${title}%0A%0A${text}%0A%0A${url}`)}>
            <span>Twitter</span>
            <FaTwitter />
          </a>
        </div>
      </CSSTransition>
    </div>
  )
}

export default ShareLinks
