import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku24.module.scss"

const Luukku24 = props => {
  //console.log('Rendering Luukku24', props)
  const nro = 24
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku24.png" alt="" />

      <h1 className="luukku-heading">Aatto </h1>
      <p>
        Ja viimein koitti se hetki, jota oli odotettu. Petteri veti juhlallisen näköisenä perässään jouluaaton
        erikoisrekeä, joka oli lastattu täpötäyteen lahjoja. Muori oli pakannut ison kassillisen eväitä Pukille,
        Kulkuselle ja Petterille sekä paljon huopia, jos matkalla tulisi kylmä. Lelutehtaan tontut, hiiriperhe, oravat
        ja koko muu Korvatunturin väki seisoivat pihassa vilkuttamassa ja toivottamassa kolmikolle hyvää
        lahjojenjakomatkaa. Joulupukki ja Kulkunen istuutuivat rekeen ja Petteri katsoi heitä merkitsevästi.
      </p>
      <p>
        – Hyvää ja turvallista matkaa! Tulkaa pian takaisin!, huusi Muori.<br></br> – Hyvää Joulua kaikille ja nähdään
        taas pian!, vastasi Kulkunen.
      </p>
      <p>Samassa reki nytkähti liikkeelle ja ampaisi vauhdilla ilmaan.</p>
      <p>Iloista ja rauhallista Joulua kaikille &#128157;</p>
    </>
  )
}

export default Luukku24
