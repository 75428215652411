import { StaticImage } from "gatsby-plugin-image"
import React from "react"
// import YoutubeEmbed from "../YoutubeEmbed"
//import * as styles from "./Luukku8.module.scss"

const Luukku8 = props => {
  //console.log('Rendering Luukku8', props)
  const nro = 8
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku8.png" alt="" />

      <h1 className="luukku-heading">Lentäen kohti Lattialuutunmäkeä</h1>

      <p>
        Tallille päästyään Pukki avasi takaseinustalla olevan violetin oven, jossa luki: Sisäänkäynti vain Pukin
        luvalla. Oven takaa paljastui valtavan kokoinen reki, joka kimmelsi ja jota koristi suunnaton määrä erivärisiä
        valoja. 
      </p>
      <p>
        – Hypätkäähän kyytiin ystävät, Pukki sanoi. Kulkunen ja Taika-tonttu loikkivat innoissaan kyytiin.<br></br> –
        Nyt paljastan teille salaisuuden, jota ette saa koskaan kertoa kenellekään. Voinko luottaa teihin?
      </p>
      <p>Taika ja Kulkunen nyökyttelivät molemmat päätään tärkeän näköisinä.</p>
      <p>
        – Katsokaas, tämä reki on Ystävyyden reki ja käytettävissä ainoastaan erityistilanteita varten. Se suostuu
        lentämään vain yhdellä tavalla.<br></br> – Millä tavalla Pukki? Kerro jo!, Kulkunen kysyi.<br></br> – Kas, reki
        käynnistyy siten, että ensin sille kerrotaan syy, miksi sitä halutaan käyttää. Eli tässä tapauksessa syy on
        huoli rakkaasta ystävästämme Petteristä sekä aikomus tuoda hänet takaisin kotiin.
      </p>
      <p>
        Samassa reki alkoi täristä voimakkaasti ja sen valot vilkkuivat. Kulkunen katsoi Taikaa jännittyneenä ja hyppäsi
        suojaan tämän syliin. 
      </p>
      <p>
        – Seuraavaksi, jotta saamme reen liikkeelle ja lentoon, tulee meidän kertoa toisillemme asioita, joiden vuoksi
        pidämme Petteristä. Asiat voivat olla isoja tai pieniä, mutta kaikkien tulee olla kauniita. Kas, minä voin
        aloittaa. Minä pidän Petteristä, koska hän kertoo maailman hauskimpia vitsejä.<br></br> – Minä haluan sanoa
        seuraavaksi, maukui Kulkunen. Minä pidän Petteristä, koska hän on suuri ja pehmoinen!<br></br> – Minä taas
        siksi, että hän keittää kaikista parhainta kaakaota, nauroi Taika. – Minä siksi, että hän keksii maailman
        parhaimmat iltasadut!
      </p>
      <p>
        Ja niin Pukki, Kulkunen ja Taika-tonttu kertoivat kilpaa asioita, joista pitivät Petterissä eivätkä ollenkaan
        huomanneet olevansa jo täydessä lennossa kohti Lattialuutunmäkeä.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku8
