import { StaticImage } from "gatsby-plugin-image"
import React from "react"

// import FormDisclaimer from "../FormDisclaimer"
//import * as styles from "./Luukku11.module.scss"

const Luukku11 = props => {
  //console.log('Rendering Luukku11', props)
  const nro = 11
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku11.png" alt="" />

      <h1 className="luukku-heading">Lumipallo kertoo </h1>
      <p>
        – Niiiin? Tunnen jääkylmissä kiteissäni, että teillä on jotakin kysyttävää, sanoi Lumipallo venyttävään
        tyyliinsä.<br></br> – Kyllä vain ja pahoittelut häiriöstä. Tässä vieressäni on tällainen Petteri-poro, kuka
        väittää, että hänen punainen nenänsä ei toimi. Tai siis ilmeisesti nenässä on joku kompassi yleensä, Isopupu
        puhisi.<br></br> – Kuonossa, Petteri sanoi.<br></br> – Ai missä?, kysyi Lumipallo.<br></br> – Kuonossa, toisti
        Petteri.<br></br> – Niin niin, kuonossa kuonossa. Enhän minä nyt kuuro ole! Sitähän minäkin juuri sanoin, sanoi
        Lumipallo.<br></br> – Niin, että mitä siis?, tuhisi Isopupu kärsimättömänä.<br></br> – Juuri sitä! Sitäpä juuri.
        Nyt hiljaa kaikki, kun mietin!, Lumipallo sanoi tomerasti.
      </p>
      <p>
        Lumipallo sulki silmänsä ja alkoi pyöriä. Vauhti oli alkuun hidas, mutta kiihtyi koko ajan. Lumipallon
        yläpuolelle alkoi hitaasti muodostua pöllyävistä lumikiteistä ikkuna ja siihen piirtyi vähitellen kissan hahmo.
      </p>
      <p>
        – Tuohan on Kulkunen!, Petteri huusi suureen ääneen ja samassa ikkuna haihtui ja Lumipallo lensi selälleen
        maahan.<br></br> – Voi kehveli teidän kanssanne! Enkö pyytänyt, että kaikki olisivat hiljaa? Nyt taika on
        kadonnut, enkä enää pääse uudestaan käsiksi ikkunaan, Lumipallo huokasi.<br></br> – Voi miten keljumainen yö
        kerta kaikkiaan. Minä lähden nyt nukkumaan. Jokainen huolehtikoon jatkossa omista ongelmistaan, sanoi Isopupu ja
        pomppi kiukkuisena puskaansa.<br></br> – Etkö siis voi auttaa mitenkään Petteriä Lumipallo?, pupuperhe aneli.
        <br></br> – No sen verran voin arvailla, että ratkaisun avain on tämä kissa, Kulkunen.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku11
