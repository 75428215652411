import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku17.module.scss"

const Luukku17 = props => {
  //console.log('Rendering Luukku17', props)
  const nro = 17
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku17.png" alt="" />

      <h1 className="luukku-heading">Lelutehtaan tontut</h1>
      <p>
        Lelutehtaalla oli koko vuoden ajan ollut melkoinen tohina päällä, joka oli vaan kiihtynyt, mitä lähemmäs
        Jouluaattoa tultiin. Lelutehtaan tontut olivat vikkeliä, hassuja ja osaavia käsistään. Heille oli tärkeää, että
        jokainen lapsi saisi toivomansa lahjan ja sitä kautta iloisen joulumielen. Olipa kyseessä sitten pulkka tai
        kauko-ohjattava auto, he kyllä osasivat sellaisen tehdä. Nyt työt alkoivat jo olla hyvällä mallilla. Enää yksi
        keppihevonen piti paketoida, mutta muuten lahjat olivat valmiina jaettaviksi. Ja hyvä niin, sillä Jouluaatto
        olikin jo huomenna!
      </p>
      <p>
        Lelutehtaan tontut kävelivät iloisina Muorin ja Pukin taloon, jossa aatonaaton puurojuhla oli alkamassa hetkenä
        minä hyvänsä. Heillä oli kova nälkä ja pelkkä ajatus Muorin herkullisesta joulupuurosta nosti veden kielelle.
      </p>
      <p>
        – Onkohan muori leiponut myös joulutorttuja tai pipareita?, yksi heistä pohti ääneen.<br></br> – No aivan
        taatusti! Varmasti myös pullia ja suklaakarkkeja, toinen vastasi.<br></br> – Minä aion syödä mahan niin täyteen,
        että housun nappi poksahtaa irti!, yksi heistä sanoi ja kaikki alkoivat nauraa yhteen ääneen.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku17
