import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku2.module.scss"

const Luukku2 = props => {
  //console.log('Rendering Luukku2', props)
  const nro = 2
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku2.png" alt="" />

      <h1 className="luukku-heading">Missä on Petteri Punakuono?</h1>
      <p>
        Yön aikana maahan oli satanut reilusti pehmeää lunta. Kulkunen katseli malttamattomana ulos ikkunalaudalla ja
        raapi lasia käpälillään. Tonttu nimeltä Taika ymmärsi välittömästi mitä Kulkunen halusi ja kiiruhti
        aamiaispöydästä raottamaan keittiön ikkunaa. Kulkunen pökkäsi Taikaa kiitokseksi ja pomppi sitten vauhdikkaasti
        omiin matkoihinsa. Kulkusella kun oli kova kiire Petteri Punakuonon luo, koska heillä oli jo pitkään ollut
        perinne mennä pulkkamäkeen ensilumen tultua.
      </p>
      <p>
        Kulkunen maukui innokkaana juostessaan kohti Petterin aitausta. Yleensä Petteri vastasi ja monesti Petteri jopa
        odotti häntä pihalla vastassa, mutta tänään Petteriä ei näkynyt.
      </p>
      <p>-Onpa merkillistä, Kulkunen ajatteli itsekseen.</p>
      <p>
        Kulkunen jatkoi kuitenkin juoksuaan ja nyt aitaus häämötti jo horisontissa. Petteri ei kuitenkaan ollut siellä.
        Minne ihmeeseen hän oli lähtenyt näin aikaisin aamulla ja miksei hän ollut jättänyt kenellekään mitään viestiä?
        Kulkunen oli kummissaan ja päätti lähteä kertomaan havainnostaan Joulupukille. Ehkä Pukki tietäisi minne Petteri
        oli mennyt.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku2
