import { StaticImage } from "gatsby-plugin-image"
import React from "react"

//import * as styles from "./Luukku14.module.scss"

const Luukku14 = props => {
  //console.log('Rendering Luukku14', props)
  const nro = 14
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku14.png" alt="" />
      <h1 className="luukku-heading">Pystyykö Punakuonon kuonon korjaamaan?</h1>

      <p>
        – Kuule Kulkunen, halauksesi on aivan ihana, mutta minä en saa henkeä, kun rutistat noin lujaa, Petteri yski.
        <br></br> – Oi voi anteeksi, Kulkunen sanoi ja irrotti otteensa. Minulla vaan on ollut niin kova ikävä, etten
        haluaisi irrottaa otettani sinusta. Lupaathan, ettet enää koskaan häviä tällä tavalla?, Kulkunen pyysi.<br></br>{" "}
        – Ei minun ollut tarkoitus huolestuttaa sinua enkä minä tahallaan kadonnut, mutta kuononi suuntavaisto lakkasi
        toimimasta. En vieläkään ymmärrä syytä siihen, sanoi Punakuono.<br></br> – Pukki kyllä ratkaisee asian tuossa
        tuokiossa! Ja tuoltahan hän jo tuleekin Taika-tontun kanssa, Kulkunen ilmoitti.
      </p>
      <p>
        – Voi hyvänen aika miten onkaan mukava nähdä sinua Punakuono, Pukki sanoi ja halasi poroa. Et arvaakaan kuinka
        huolissaan olimme.<br></br> – Syy kaikkeen on selvinnyt Pukki! Punakuonon kuonon suuntavaisto on rikki. Vika on
        siinä! Tiedätkö sinä, miten kuonoja korjataan?, Kulkunen kysyi.<br></br> – Ahaa, vai sillä tavalla. Olen kerran
        kuullut vastaavasta tapauksesta kyllä. Hm, mutta asiaa pitää pohtia. Muistaakseni kirjoitin ohjeen tämän
        kaltaisten tilanteiden varalle muistikirjaani. Selvitetään asia heti, kun päästään takaisin Korvatunturille,
        Pukki sanoi.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku14
