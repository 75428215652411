import { StaticImage } from "gatsby-plugin-image"
import React from "react"

// import FormDisclaimer from "../FormDisclaimer"
//import * as styles from "./Luukku9.module.scss"

const Luukku9 = props => {
  //console.log('Rendering Luukku9', props)
  const nro = 9
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku9.png" alt="" />
      <h1 className="luukku-heading">Petterin punainen kuono ei toimi</h1>

      <p>
        Petteri kuljeskeli metsän keskellä ja yritti löytää omia kavionjälkiään. Taivaalta tuprutti kuitenkin niin
        sakeasti lunta, että jäljet olivat jo aikoja sitten peittyneet.
      </p>
      <p>– Mistäköhän ihmeen suunnasta minä oikein tulin?, Petteri mietiskeli itsekseen.</p>
      <p>
        Oli kummallista, ettei Petteri punaisella kuonollaan tunnistanut oikeaa suuntaa, koska sen avulla hän aina
        yleensä suunnisti. Nyt kuono pysyi kuitenkin aivan hiljaa eikä kertonut mihin suuntaan hänen tulisi lähteä.
      </p>
      <p>
        – Pitäisiköhän kuonoa vähän herätellä, Petteri tuumasi ja tunki sen syvälle lumihankeen. Mutta mitään ei
        tapahtunut. Ehkäpä se auttaisi, jos kuonolla rapsuttelisi hitusen puunrunkoa?
      </p>
      <p>
        – Pitäisiköhän kuonoa vähän herätellä, Petteri tuumasi ja tunki sen syvälle lumihankeen. Mutta mitään ei
        tapahtunut.<br></br> – Ehkäpä se auttaisi, jos kuonolla rapsuttelisi hitusen puunrunkoa?
      </p>
      <p>Ja niin Petterin raapi kuonollaan pitkin puunrunkoa, mutta sekään ei auttanut.</p>
      <p>
        Pupuperhe oli tarkkaillut puskan alta Petterin touhua jo jonkin aikaa ja hyppelehti nyt hänen luokseen
        tiedustelemaan, mitä ihmettä tämä oikein teki.
      </p>
      <p>
        – Kuka sinä oikein olet ja miksi pyyhit nenääsi meidän kotipuuhumme?, Isäpupu tiedusteli.<br></br> – Minä olen
        Petteri Punakuono enkä minä pyyhi nenää puuhunne. Yritän tässä vaan saada kuononi toimimaan.<br></br> – Mitä
        tarkoitat? Eikö sinun nenäsi toimi automaattisesti? Meidän neniä ei tarvitse erikseen käynnistää, pupuperhe
        sanoi.<br></br> – No ei minunkaan ja toimiihan se muuten, mutta kuononi suuntavaisto on mennyt rikki enkä siksi
        löydä takaisin kotiini Korvatunturille.<br></br> – Ahaa! Nyt ymmärrän. Ehkä voimme auttaa sinua. Tulehan, niin
        mennään kysymään Isopupulta neuvoa. Hän asuu ihan tuossa muutaman puskan päässä, Isäpupu sanoi.
      </p>

      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku9
