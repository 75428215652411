import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku16.module.scss"

const Luukku16 = props => {
  //console.log('Rendering Luukku16', props)
  const nro = 16
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku16.png" alt="" />
      <h1 className="luukku-heading">Muori keittää joulupuuroa</h1>
      <p>
        Korvatunturilla Muori hämmensi joulupuuroa jättimäisessä kattilassa. Hän lauloi kovaan ääneen joululauluja ja
        oli erinomaisella tuulella. Muorilla ja Joulupukilla oli koko elämänsä ollut sellainen taianomainen yhteys, että
        ilman sanojakin he tiesivät mitä toiselle kuului. Nytkin Muori tiesi, että Petteri oli löytynyt ja, että he
        olivat kaikki turvallisesti matkalla kotia kohti. Kukaan ei ollut ilmoittanut asiasta Muorille, mutta jotenkin
        hän vaan sen tiesi. Mikään kun ei ollut mahdotonta Korvatunturilla.{" "}
      </p>
      <p>
        – Onpa taas niin ihana saada Petteri takaisin kotiin. Ja olisihan Jouluaaton lahjojenjakokin mennyt hankalaksi
        ilman Petteriä, Muori hyräili tyytyväisenä itsekseen ja lisäsi puuron joukkoon pussillisen manteleita.
      </p>
      <p>
        Seuraavaksi hän paketoi kauniilla paperilla ison rasiallisen erikois-joulupipareita, jotka muori oli leiponut
        varta vasten joululahjaksi Petterille. Hän odotti jo hetkeä, että pääsisi antamaan ne hänelle. Petteri pitäisi
        lahjasta varmasti.
      </p>
      <p>Kyllä Joulu oli vaan ihanaa aikaa!, muori ajatteli samalla kun sytytti joulukuuseen kynttilöitä.</p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku16
