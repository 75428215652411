import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku13.module.scss"

const Luukku13 = props => {
  //console.log('Rendering Luukku13', props)
  const nro = 13
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku13.png" alt="" />
      <h1 className="luukku-heading">Kulkusen ja Punakuonon jälleennäkeminen</h1>

      <p>
        Punakuono istui pupuperheen kanssa heidän kotipuskassaan. He söivät porkkanoita ja miettivät mikä ratkaisuksi.
      </p>

      <p>– Mitenköhän Kulkunen liittyy tähän?, Petteri kummasteli.</p>
      <p>Samassa pupuperheen pienin alkoi kiljua:</p>
      <p>– Vilkkuva jättilintu! Katsokaa! Taivaalla! Nopeasti suojaan!</p>
      <p>
        Ja niin koko porukka työnsi vauhdilla päänsä piiloon lumihankeen kuvitellen, että näin he olisivat suojassa.
        Mutta eihän taivaalla mikään jättilintu lentänyt, vaan se oli Ystävyyden reki,joka oli juuri laskeutumassa
        puskan viereen, lumihankien väliin.
      </p>
      <p>
        – Huhuu! Petterii! Kuuletko?! Me tulimme hakemaan sinua!, Kulkunen huusi onnellisena ja ampaisi juoksuun. –
        Aivan kuin kuulisin Kulkusen äänen, Petteri mietiskeli itsekseen pää edelleen piilossa lumihangessa. Taidanpa
        vaan kuvitella, koska ikävöin häntä ja koko Korvatunturin väkeä niin kovasti.
      </p>
      <p>Mutta kohta Petteri kuitenkin tunsi tutut tassut selkänsä päällä sekä kuuli ihanaa kehräystä.</p>
      <p>
        – Kulkunen! Se on selvästi Kulkunen!, Petteri riemastui ja nosti päänsä lumihangesta. Sinä se toden totta olet!
        Onpa ihana nähdä sinua! Mutta, miten kummassa löysit tänne?, Petteri kysyi.<br></br> – Helposti rakas ystäväni,
        sangen helposti. Monien oivallisten piirteideni lisäksi olen myös erinomaisen hyvä suunnistaja, kuten tiedät,
        Kulkunen hyrisi ylpeänä ja kietoi etutassunsa Petterin kaulan ympärille halaukseen ja päätti, ettei milloinkaan
        paljastaisi Petterille, että hän oli piilottanut kännykän tämän satulalaukkuun ja löytänyt ystävänsä sen avulla.
      </p>
      {/* <p>
        <em>
          Lucian päivän kunniaksi Glögiä ja joulupiparia tarjolla Porin YH-Asunnot Oy:n toimistolla Annankatu 8:ssa klo
          10-15.30.
        </em>
      </p> */}

      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku13
