import React from "react"
import { cls } from "../utils"

import * as styles from "./Luukku.module.scss"

export const luukkuStatus = {
  AVAILABLE: "available",
  LOCKED: "locked",
  CURRENT: "current",
  OPEN: "open",
} as const

export type LuukkuStatus = typeof luukkuStatus[keyof typeof luukkuStatus]

type LuukkuProps = {
  /** Joulukalenteri/luukkuStatus */
  status: LuukkuStatus
  num: number
  /** callback  */
  onClick?: () => void
}

const Luukku = (props: LuukkuProps) => {
  // console.log("Rendering Luukku", props)

  return (
    <button
      className={cls(
        styles.container,
        props.status === luukkuStatus.OPEN && styles.open,
        props.status === luukkuStatus.AVAILABLE && styles.available,
        props.status === luukkuStatus.CURRENT && styles.current
      )}
      onClick={props.onClick}
      title={"Luukku " + props.num}>
      <div className={styles.avatunLuukunNumero}>{props.num}</div>

      <div className={cls(styles.kannen_tausta)}></div>

      <div className={cls(styles.kansi)}>{props.num}</div>
    </button>
  )
}

export default Luukku
