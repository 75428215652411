import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku6.module.scss"

const Luukku6 = props => {
  //console.log('Rendering Luukku6', props)
  const nro = 6
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku6.png" alt="" />

      <h1 className="luukku-heading">Taika-tonttu auttaa paikallistamaan Punakuonon</h1>
      <p>
        Kulkunen juoksi vauhkona Taika-tontun huoneeseen ja kertoi oivalluksestaan, ja siitä kuinka hän oli kätkenyt
        Petteri Punakuonon satulalaukkuun kännykän juuri tämän tapaisten tilanteiden varalta. 
      </p>
      <p>
        – Hetkinen, rauhoitutaanpas hiukan. Eli tarkoitatko, että voimme jotenkin löytää Petterin iPadin avulla?
        <br></br> – Juuri niin Taika! Pystymme jäljittämään Petterin puhelimen ja sitä kautta myös Petterin! Miten
        ällistyttävän nerokas olinkaan, kun kätkin puhelimen Petterin mukaan! En haluaisi kehua itseäni, mutta teen sen
        silti, koska vaatihan moinen päähänpisto nokkeluutta.
      </p>
      <p>
        Kulkunen ei meinannut pysyä nahoissaan, koska oli niin innoissaan. Aivan kohta hän löytäisi parhaan ystävänsä ja
        pääsisi laskemaan mäkeä tämän kanssa.
      </p>
      <p>
        – No niin, katsotaanpas sitten, että missä Petteri oikein on, Taika-tonttu sanoi ja nosti iPadin syliinsä.
        <br></br> – No missä? Missä? Kerro jo ja lakkaa panttaamasta tietoa!, Kulkunen komensi.<br></br> – Näyttäisi
        siltä, että Petteri on Lattialuutunmäellä.<br></br> – Lattialuutun? Eihän tuon nimistä paikkaa voi olla
        olemassa. Eikö se ole se outo vekotin, jolla muori huiskii lattioita joka lauantai?<br></br> – Juurikin se! Ja
        on sellainen olemassa, ainakin tämän kartan mukaan. Mutta en kyllä ole koskaan siellä käynyt ja näyttäisi olevan
        kovin kaukanakin.<br></br> – Mikään ei ole liian kaukana, kun ystävä on hädässä. Minä olen valmis minuutin
        päästä. Lähtekäämme heti hakemaan Petteri kotiin, Kulkunen ilmoitti.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku6
