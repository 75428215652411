import { StaticImage } from "gatsby-plugin-image"
import React from "react"
// import Lomake from "../Lomake"
//import * as styles from "./Luukku23.module.scss"

const Luukku23 = props => {
  //console.log('Rendering Luukku23', props)
  const nro = 23
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku23.png" alt="" />
      <h1 className="luukku-heading">Petteri Punakuonon syntymäpäivä</h1>

      <p>
        Petteri Punakuono oli hänkin herännyt hiirten lauluun ja jolkotti nyt kohti Muorin ja Pukin taloa. Ilma oli mitä
        parhain lahjojen jakamiseen ja lentämiseen, sillä taivaalla ei ollut ainuttakaan pilven hattaraa. Petteri puhkui
        intoa ja odotusta. Tämä oli vuoden tärkein päivä ja samalla myös hänen syntymäpäivänsä.
      </p>
      <p>
        Muori oli leiponut Petterille yllätykseksi ison punaisen kakun, jossa oli ainoastaan hänen lempitäytteitään. Eli
        mansikkaa, suklaata, porkkanoita ja suolasikaloita. Kakku oli ainoastaan hänelle, koska jostain syystä muut
        vierastivat kyseistä makuyhdistelmää.
      </p>
      <p>– No parempi niin, jäisipä enemmän kakkua hänelle, Petteri maiskutti tyytyväisenä.</p>
      <p>
        – Mitä ihmettä sinä syöt?, Kulkunen pomppasi pöydälle ja kysyi.<br></br> – Muorin leipomaa kakkua. Maista
        ihmeessä sinäkin, Petteri vastasi.<br></br> – Maistaisin mielelläni, mutta en voi sietää mansikoita, suklaasta
        puhumattakaan ja sitä paitsi minulla on hirveä kiire. Kuulitko jo, että minä lähden sinun ja Joulupukin kanssa
        jakamaan lahjoja tänään?<br></br> – Onpa mahtavia uutisia! Päivä vaan paranee koko ajan!, Petteri sanoi
        innoissaan.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku23
