import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "../context/UserContext"
import * as styles from "./Joulukalenteri.module.scss"
import Luukku, { luukkuStatus, LuukkuStatus } from "./Luukku"

import { devMode } from "./Kalenterisivu"
import { gtag } from "./SEO"

type Luukku = {
  ind: number
  status: LuukkuStatus
}

const initLuukut: Luukku[] = [...Array(24).keys()].map(ind => {
  return { ind: ind, status: luukkuStatus.LOCKED }
})

type JoulukalenteriProps = {
  onOpen: (dayNum: number) => void
}

/**
 */
const Joulukalenteri = (props: JoulukalenteriProps) => {
  // console.debug("Rendering Joulukalenteri", props, devMode)

  const { opened, addOpen, hasOpen } = useContext(UserContext)

  const [luukut, setLuukut] = useState(initLuukut)

  /** Minkä vuoden joulukalenteri */
  const campaignYear = 2023

  // evaluate luukku status (current|available|open), in client, every time UserContext "opened" changes
  useEffect(() => {
    const date = new Date()
    const dayOfMonth = date.getDate()

    const newLuukut = luukut.map(luukku => {
      // Sallitaan oikeiden luukkujen avaaminen
      if (
        devMode ||
        date.getFullYear() > campaignYear ||
        (date.getFullYear() === campaignYear && luukku.ind < dayOfMonth && date.getMonth() === 11)
      ) {
        if (luukku.ind + 1 === dayOfMonth) {
          luukku.status = luukkuStatus.CURRENT
        } else {
          luukku.status = luukkuStatus.AVAILABLE
        }
      }
      // avataan aikaisemmin avatut
      if (hasOpen(luukku.ind)) {
        luukku.status = luukkuStatus.OPEN
      }
      return luukku
    })

    // console.debug("Joulukalenteri useEffect luukut", luukut, newLuukut)
    setLuukut(newLuukut)
  }, [opened])

  const luukkuClick = (luukku: Luukku) => {
    // console.debug("luukkuClick", luukku, luukut)

    if (luukku.status === luukkuStatus.LOCKED) {
      // luukku is locked so do nothing on click
      return
    }
    if (luukku.status === luukkuStatus.OPEN) {
      // luukku is open so call the callback if there's one
      props.onOpen && props.onOpen(luukku.ind)
    } else {
      // luukku should be opened...
      // 1. update status
      luukku.status = luukkuStatus.OPEN

      // 2. add it UserContext as opened (other parts of the app react to this)
      addOpen(luukku.ind)

      // 3. after a delay for opening animation, call the callback if there's one
      setTimeout(() => {
        props.onOpen && props.onOpen(luukku.ind)
      }, 800)

      // 4. fire a GTM event
      gtag("Kalenteri", "click", "Luukku", luukku.ind)
    }
  }

  return (
    <>
      <div className={styles.container}>
        {luukut &&
          luukut.map((luukku, i) => {
            return (
              <Luukku
                key={luukku.ind}
                num={luukku.ind + 1}
                status={luukku.status}
                onClick={() => luukkuClick(luukku)}
              />
            )
          })}
      </div>
    </>
  )
}

export default Joulukalenteri
