import { StaticImage } from "gatsby-plugin-image"
import React from "react"

// import Voittoviesti from "../Voittoviesti"
//import * as styles from "./Luukku15.module.scss"

const Luukku15 = props => {
  //console.log('Rendering Luukku15', props)
  const nro = 15
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku15.png" alt="" />
      <h1 className="luukku-heading">Kotimatka alkaa</h1>

      <p>
        Punakuono, Kulkunen, Pukki ja Taika-tonttu hyvästelivät pupuperheen ja lähtivät kävelemään kohti Ystävyyden
        rekeä.
      </p>
      <p>
        – Onpa hauskan näköinen reki! En ole koskaan nähnyt tätä aiemmin. Luulin tietäväni Korvatunturin kaikki reet,
        Punakuono sanoi.<br></br> – Tämä reki on Ystävyyden reki ja erittäin salainen. Pukkikaan ei ollut matkustanut
        tällä aiemmin kuin nyt. Sitä saa käyttää ainoastaan erikoistilanteissa, Kulkunen supatti tärkeänä parhaan
        ystävänsä korvaan.<br></br> – Onpa jännittävää! Mutta missä kaikki porot ovat?, Punakuono tiedusteli.<br></br> –
        No se juuri on jännintä, että poroja ei lainkaan tarvita, Kulkunen kertoi.
      </p>
      <p>
        – Nyt kaikki kyytiin hop hop, Pukki sanoi. Jos lähdemme heti, saatamme hyvinkin ehtiä aatonaaton perinteiseen
        puurojuhlaan.<br></br> – Mutta ketä nyt kehutaan, jotta päästään lentoon?, Taika-tonttu tiedusteli.
      </p>
      <p>Petteri Punakuono näytti aivan kysymysmerkiltä, koska ei ymmärtänyt alkuunkaan mistä Taika oikein puhui.</p>
      <p>
        –Reki siis nousee ilmaan ja lentää ainoastaan silloin, kun koko matkan ajan kerrotaan kehuja. Tänne tullessa
        kerroimme asioita, joista pidämme sinussa, Kulkunen selosti tilanteen Petterille.<br></br> – Aivan niin tosiaan.
        Ketäs me nyt kehuisimme?, Pukki pohti ääneen.<br></br> – Minusta meidän kuuluisi kehua Kulkusta, Petteri sanoi
        äkkiä. Sen lisäksi, että Kulkunen on maailman paras ystävä, on hän myös varsin nokkela ja erittäin hyvä
        suunnistaja! Ilman häntä en olisi koskaan päässyt takaisin kotiin.
      </p>
      <p>
        Kaikki olivat totta kai samaa mieltä ja siinä samassa reki oli jälleen ilmassa ja lentämässä kovaa vauhtia kohti
        Korvatunturia.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku15
