import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku20.module.scss"

const Luukku20 = props => {
  //console.log('Rendering Luukku20', props)
  const nro = 20
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku20.png" alt="" />

      <h1 className="luukku-heading">Kulkunen ja Punakuono vaihtavat lahjoja</h1>
      <p>
        Kulkusella ja Punakuonolla oli oma salainen jouluperinteensä. He vaihtoivat lahjoja aina Jouluaattoa edeltävänä
        yönä tasan klo 12. Tänä vuonna Kulkunen oli pyytänyt tonttuja tekemään Petterille lahjaksi sukset, jotta he
        voisivat yhdessä harjoitella laskettelua. Olisi hulvattoman hauskaa lähteä testaamaan suksia heti Joulupäivänä,
        kun lahjojenjako oli tehty ja kaikki Korvatunturilla aloittivat Joulun vieton. Petteri puolestaan oli pyytänyt
        tonttuja valmistamaan Kulkuselle pienen punaisen selkärepun, jonne hän voisi pakata retkelle lähtiessään eväitä
        ja muita tärkeitä tavaroita.
      </p>
      <p>
        – Kiitos Petteri! Tämä lahja on ihan huippu! Mistä keksit, että juuri tällaisen minä tarvitsen?, Kulkunen kysyi.
        <br></br>– Tuli vaan mieleen. Ajattelin, että sinun ei tarvitsisi sitten laittaa tavaroitasi minun
        satulalaukkuuni. Kuten esimerkiksi kännyköitä ja sen sellaisia, Petteri nauroi.<br></br> – Senkin salapoliisi!
        Sinä siis tiesit puhelimesta koko ajan?, Kulkunen nauroi takaisin.<br></br> – No totta kai tiesin ja latasinkin
        sitä aina välillä puolestasi. Kyllä minä sinut tunnen ystäväni. Ja sitä paitsi, puhelinhan tuli nyt ihan viime
        päivinä todella tarpeeseen. En muuten olisi varmaan koskaan löytänyt takaisin kotiin, ettekä te olisi löytäneet
        minua. Kiitos vielä sinulle, kun olet niin ovela”, Petteri halasi ystäväänsä.<br></br> – Ei kestä kiittää.
        Oveluus tulee minulta ihan luonnostaan, hyrisi Kulkunen.
      </p>

      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku20
