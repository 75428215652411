import { StaticImage } from "gatsby-plugin-image"
import React from "react"
//import * as styles from "./Luukku21.module.scss"

const Luukku21 = props => {
  //console.log('Rendering Luukku21', props)
  const nro = 21
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage className="luukku-img" src="../../images/luukut/PorinYH-asunnot_Luukku21.png" alt="" />
      <h1 className="luukku-heading">Puhuva Lumipallo vierailee Petterin luona </h1>
      <p>
        Petteri kääntelehti aitauksessaan eikä meinannut millään saada unta. Hänen punaista kuonoaan kutisi
        kummallisesti. Välillä siihen syttyi myös valo. 
      </p>
      <p>– No nyt on merkillistä, Petteri mietiskeli ääneen ja aivasteli.</p>
      <p>
        Samassa alkoi tuulla voimakkaasti ja lumi pöllysi. Petteri yritti sulkea silmänsä, mutta avasi ne taas, sillä
        hän kuuli puhetta. Puhe kumisi ja tuli kuin jostain kaukaa. Petterin viereen oli yhtäkkiä ilmestynyt samanlainen
        ikkuna, kuin Puhuvan Lumipallon ja pupujen luona, mutta nyt ikkunassa näkyi selvästi Puhuva Lumipallo itse.
      </p>

      <p>
        – Ha haa, sainpas sinuun yhteyden vielä! Melkoista noidan työtä vai mitä? Joko muuten olet selvittänyt syyn
        suuntavaistosi katoamiseen?, Lumipallo kysyi.<br></br> – En vielä. Kuinka niin?, Petteri vastasi.<br></br> –
        Sitä vaan, että sinun ei enää tarvitsekaan, koska nenäsi toimii taas!<br></br> – Niinkö? Onpa hienoa! Mutta
        oletko varma?<br></br> – Aivan satavarma. Tunnen sen lumikiteissäni. Usko pois, huomenna osaat lennättää
        Joulupukkia osoitteesta toiseen aivan kuten kaikkina muinakin vuosina, Lumipallo lupasi.<br></br> – Onpa hienoja
        uutisia! Kiitos sinulle Lumipallo. Ja vielä yksi juttu: se on kuono!<br></br> – Ai mikä vuono? Noh, en ehdi nyt
        jutustella tämän pidempään. Hyvää Joulua sinulle punanenä!
      </p>
      <p>
        Ja aivan yhtä nopeasti kuin salainen ikkuna oli ilmestynyt, niin se myös katosi ja Petteri vaipui syvään uneen.
      </p>
      <p className="luukku-ending">Jatkuu seuraavassa luukussa.</p>
    </>
  )
}

export default Luukku21
